import api from "./api";

export default {
    api: 'rdshs',
    /**
     * Get all RDSH server
     * @returns {Promise}
     */
    list() {
        return new Promise((resolve, reject) => {
            api().get(this.api).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get default template for rdsh server
     * @returns {Promise}
     */
    getTemplate() {
        return new Promise((resolve, reject) => {
            api().get('settings/pools/' + this.api).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get RDSH server by id
     * @param {string|number} id RDSH server id
     * @returns {Promise}
     */
    get(id) {
        return new Promise((resolve, reject) => {
            api().get(this.api + '/' + id).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Create new RDSH server
     * @param {object} data RDSH server object
     * @returns {Promise}
     */
    add(data) {
        return new Promise((resolve, reject) => {
            api().post(this.api, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Update RDSH server object by id
     * @param {object} data RDSH server object with all attr, includes id
     * @returns {Promise}
     */
    edit(data) {
        return new Promise((resolve, reject) => {
            api().put(this.api + '/' + data.id, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Delete one or more RDSH server by id
     * @param {array} data Array of RDSH server ids
     * @returns {Promise}
     */
    delete(data) {
        return new Promise((resolve, reject) => {
            api().delete(this.api, {data: data}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    }
};
